import * as employeePlanTypes from '../types/empPlanTypes';

const initialState = {
    employeeList: undefined
}

export default function employeePlanReducer(state = initialState, action) {
    let userData
    switch (action.type) {
        case employeePlanTypes.EMP_PLAN_FETCH_SUCCESS:
            userData = action.payload
            state = {
                ...state,
                employeeList: userData
            }

            return state
        default:
            return state
    }
}
