// eslint-disable-next-line
import React from 'react';
// eslint-disable-next-line
import compport from './compport-loader.gif';
import * as config from '../config';

const Loader = props => {
  // eslint-disable-next-line
  const { height, width, sectionFill, trackFill } = props;

  try {
    let a = config.getParentDOMInfo();
    window.parent.postMessage({
      action: "okr_app_auth",
      called_from: window.location.href
    }, a.parent_url);
  } catch (e) {

  }

  return ("");
};


export default Loader;
