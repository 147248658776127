import * as commonTypes from '../types/commonTypes'

const initialState = {
    translationKey: undefined,
}

export  function translationReducer(state = initialState, action) {
    let data
    switch (action.type) {
        case commonTypes.TRANS_KEY_FETCH_SUCCESS:
            data = action.payload
            return  {
                ...state,
                translationKey: data,
            }
        case commonTypes.UPDATE_PAGE_FETCH_SUCCESS:
            data = action.payload
            return  {
                ...state,
                updatePageInfo: data,
            }
        default:
            return state
    }
}

