import * as empGoalTypes from '../types/empGoalTypes'

const initialState = {}

export default function employeeGoalReducer(state = initialState, action) {
    let data
    switch (action.type) {
        case empGoalTypes.SET_EMP_PLAN_LIST:
            data = action.payload
            state = {
                ...state,
                empPlanDetails: data,
            }

            return state
        case empGoalTypes.CHECK_MATRIX_MANAGER_SUCCESS:
            data = action.payload
            state = {
                ...state,
                uid: data.uid,
            }

            return state
        default:
            return state
    }
}