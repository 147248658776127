// import "react-app-polyfill/ie11"; // For IE 11 support
// import "react-app-polyfill/stable";
// import "core-js";
// import "./polyfill";
// import React from "react";
// import ReactDOM from "react-dom";
// import App from "./App";
// import * as serviceWorker from "./serviceWorker";
// import * as config from "./config"
// import { icons } from "./assets/icons";
import './polyfill';
import 'core-js';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-notifications/lib/notifications.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { NotificationContainer } from 'react-notifications';
import { Provider } from 'react-redux';

import App from './App';
import { icons } from './assets/icons';
import * as config from './config';
import * as serviceWorker from './serviceWorker';
import store from './store';

React.icons = icons;
var ParentInfoLoaded = false
let parent_origin = false;
// set API config
config.setConfig();
async function loadApp() {
    console.log("inside load app", document.referrer)
    if (document.referrer) {
        let a = config.getParentDOMInfo();
        if (!a) {
            localStorage.setItem("parent_frame_info", btoa(JSON.stringify({ parent_url: document.referrer })));
            parent_origin = true
        } else {
            parent_origin = true
        }
    }
    establishConnection().then( function(result) {
        console.log("established");
    })
    if (parent_origin) {
        let a = config.getParentDOMInfo();
        window.parent.postMessage({
            action: "okr_app_auth",
            called_from: window.location.href
        }, a.parent_url);
        window.addEventListener('message', function(event){
            if (typeof event.data == "object") {
                if (event.data.action === "okr_app_auth") {
                    ReactDOM.render(
                        <Provider store={store}>
                            <NotificationContainer />
                            <App />
                        </Provider>,

                        document.getElementById("root")
                    );
                }
            }

        });
    } else {
        ReactDOM.render(
            <Provider store={store}>
                <NotificationContainer />
                <App />
            </Provider>,
            document.getElementById("root")
        );
    }
}

function establishConnection() {
    return new Promise((resolve, reject) => {
        if (document.referrer) {
            if (!ParentInfoLoaded && !document.referrer.includes(window.location.origin)) {
                let a = document.referrer;
                window.parent.postMessage({
                    action: "okr_app_set_parent_config"
                }, a);
                window.addEventListener('message', function(event){
                    if (typeof event.data == "object") {
                        if (event.data.action === "okr_app_set_parent_config") {
                            localStorage.setItem("parent_frame_info", btoa(JSON.stringify(event.data)));
                            return resolve(true)
                        }
                    }

                });
            }
        } else {
            ParentInfoLoaded = true;
            return resolve(true)
        }
    })
}

loadApp()
serviceWorker.unregister();
