import * as planTypes from '../types/planListTypes';

const initialState = {
    planList: undefined,
    defaultColumns : undefined,
    planfields : undefined,
}

export  function planListReducer(state = initialState, action) {
    let userData
    switch (action.type) {
        case planTypes.PLAN_FETCH_SUCCESS:
            userData = action.payload.data
            return  {
                ...state,
                planList: userData,
                planfields: action.payload.fields
            }
        default:
            return state
    }
}
export  function deletePlanReducer(state = initialState, action) {
    let userData
    switch (action.type) {
        case planTypes.PLAN_FETCH_SUCCESS:
            userData = action.payload
            return  {
                ...state,
                planList: userData
            }
        default:
            return state
    }
}
