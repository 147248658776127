import { combineReducers } from 'redux';

import { translationReducer } from './commonReducer';
import employeeGoalReducer from './employeeGoalReducer';
import employeePlanReducer from './employeeplanReducer';
import { filterReducer } from './filterReducer';
import { matrixManagerListReducer } from './matrixManagerListReducer';
import { planListReducer } from './planListReducer';
import { ruleReducer } from './ruleReducer';
import { threeSixtyListReducer } from './threeSixtyListReducer';
import { employeeManagerReducer } from './employeeManagerReducer';
// import { employeeListReducer } from './employeeListReducer';

export default combineReducers({
  employeeGoalReducer,
  planListReducer,
  translationReducer,
  employeePlanReducer,
  matrixManagerListReducer,
  threeSixtyListReducer,
  filterReducer,
  ruleReducer,
  employeeManagerReducer,
  // employeeListReducer
})