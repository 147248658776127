import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./scss/main.scss";
import * as config from "./config.js";
import * as Roles from "./Roles.js";
import "./views/base/forms/VerfyUser";
import { Provider } from 'react-redux'
import store from './redux/store'
import Loader from "./reusable/loader";
require("dotenv").config();

const loading = <Loader trackFill="#C6CCD2" sectionFill="#3F4850" />;
const TheLayout = React.lazy(() => import("./containers/TheLayout"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const Pageunauthorized = React.lazy(() =>
  import("./views/pages/unauthorized/Pageunauthorized")
);

const GoalFormController = React.lazy(() =>
  import("./views/base/forms/GoalFormController")
);
const Hierarchy = React.lazy(() =>
  import("./reusable/Hierarchy")
);
const EmployeePlan = React.lazy(() =>
  import("./views/base/forms/EmployeePerformance")
);

const EmployeeManager = React.lazy(() =>
  import("./views/base/forms/EmployeeManager")
);

const VerifyUser = React.lazy(() => import("./views/base/forms/VerfyUser"));

const EmployeeGoal = React.lazy(() =>
  import("./views/base/forms/employeeGoal/EmployeeGoal")
);

const PerformancePlanList = React.lazy(() =>
  import("./views/base/forms/PerformancePlanList")
);
const ThreeSixtyRating = React.lazy(() =>
  import("./views/base/forms/ThreeSixtyRating")
);

const EmployeeStatusForPlan = React.lazy(() =>
  import("./views/base/forms/EmployeeStatusForPlan")
);

const MatrixManagerList = React.lazy(() =>
  import("./views/base/forms/MatrixManagerList")
);

const ThreeSixtyList = React.lazy(() =>
  import("./views/base/forms/ThreeSixtyEmployeeList")
);

function App(prop) {
  return (
    <div>
      <Provider store={store}>
        <BrowserRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/three-sixty-degree-rating/:peer_id/:perf_plan_id/:emp_plan_id`}
                name="360° Ratings" //press alt+0176
                render={(props) => <ThreeSixtyRating {...props} />}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/my-employee-list/:perf_plan_id`}
                name="Employee List"
                render={(props) => <EmployeeStatusForPlan {...props} />}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/matrix-manager-list`}
                name="Matrix Manager List"
                render={(props) => <MatrixManagerList {...props} />}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/hierarchy`}
                name="Hierarchy"
                render={(props) => <Hierarchy {...props} />}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/three-sixty-list`}
                name="360 List"
                render={(props) => <ThreeSixtyList {...props} />}
              />
              {/* --------------------------------------------------------------------------------------------------------- */}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/employee-plan`}
                name="Employee Plan"
                render={(props) =>
                  Roles.checkPermission(config.pages.employee_plan) ? (
                    <EmployeePlan {...props} />
                  ) : (
                    <Page404 />
                  )
                }
              ></Route>
              {/* --------------------------------------------------------------------------------------------------------------- */}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/employee-status`}
                name="Employee Manager"
                render={(props) => <EmployeeManager {...props} />}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/verify-user`}
                name="Verify User"
                render={(props) => <VerifyUser {...props} />}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/employee-goal/:emp_plan_id/:perf_plan_id/:callerPage`}
                name="Employee Goal"
                render={(props) => <EmployeeGoal {...props} />}
              />

              <Route
                exact
                path="/goalformcontroller/:action/:id"
                name="Goal Form Controller"
                render={(props) => <GoalFormController {...props} />}
              />
              <Route
                exact
                path="/performance-plan"
                name="Performance Plan List"
                render={(props) => <PerformancePlanList {...props} />}
              />
              <Route
                exact
                path="/register"
                name="Register Page"
                render={(props) => <Register {...props} />}
              />
              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={(props) => <Page500 {...props} />}
              />
              <Route
                exact
                path="/forbidden"
                name="Page 500"
                render={(props) => <Pageunauthorized {...props} />}
              />
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
