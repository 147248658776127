import * as matrixManagerListTypes from '../types/matrixManagerListTypes';

const initialState ={
    matrixManagerList : undefined
}

export function matrixManagerListReducer(state = initialState, action){
    let userData;
    switch (action.type) {
        case matrixManagerListTypes.LIST_FETCH_SUCCESS:
            userData = action.payload;
            state = {
                ...state,
                matrixManagerList : userData,
            }
            return state;
    
        default:
            return state;
    }
} 