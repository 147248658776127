import * as threeSixtyListTypes from '../types/threeSixtyListType';

const initialState={
    threeSixtyList : undefined
}

export function threeSixtyListReducer(state = initialState, action){
    let userData;
    let userDataFields;
    switch (action.type) {
        case threeSixtyListTypes.FETCH_SUCCESS:
         userData = action.payload.data;
         userDataFields = action.payload.fields;
            state = {
                ...state,
                threeSixtyList : {data : userData},
                threeSixtyListFields : { fields : userDataFields }
            }
            return state;
        default:
            return state;
    }
}