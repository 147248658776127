import middlewares from 'redux-thunk'
import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import reducers from './reducers/rootReducer.js'
const persistConfig = {
  key: 'root',
  storage,
}
const persistedReducer = persistReducer(persistConfig, reducers)
const store = createStore(
  persistedReducer,
  compose(
    applyMiddleware( middlewares),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  )
);
export const persistor = persistStore(store)
export default store