import * as filterTypes from '../types/filterTypes';

    const initialState ={
        filterData : undefined
    }

    export function filterReducer(state = initialState, action) {
        let userData
        switch (action.type) {
            case filterTypes.FETCH_FILTER_DATA:
                userData = action.payload
                state = {
                    ...state,
                    filterData : userData
                }

                return state
            default:
                return state
        }
    }