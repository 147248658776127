import * as employeeManagerTypes from '../types/employeeManagerTypes';

const initialState = {
    planList: undefined,
    empList: undefined,
    // empRating: undefined,
    // empAssessment: undefined,
    // empRatingModel: undefined
}

export function employeeManagerReducer(state = initialState, action) {
    let userData
    switch (action.type) {
        case employeeManagerTypes.EMP_STATUS_FETCH_SUCCESS:
            userData = action.payload
            state = {
                ...state,
                planList: userData
            }

            return state

        case employeeManagerTypes.EMP_FETCH_SUCCESS:
            userData = action.payload
            state = {
                ...state,
                planEmpList: userData
            }

            return state

        case employeeManagerTypes.EMP_RATING_FETCH_SUCCESS:
            userData = action.payload
            state = {
                ...state,
                empRating: userData
            }

            return state

        case employeeManagerTypes.EMP_ASSESSMENT_FETCH_SUCCESS:
            userData = action.payload
            state = {
                ...state,
                empAssessment: userData
            }

            return state

        case employeeManagerTypes.EMP_RATING_MODEL_FETCH_SUCCESS:
            userData = action.payload
            state = {
                ...state,
                empRatingModel: userData
            }

            return state
        default:
            return state
    }
}
