import axios from "axios";
import * as config from "../../../config";
// import * as Roles from "../../../Roles";
import queryString from "query-string";
import { NotificationManager } from "react-notifications";

async function VerfyUser(props) {
  let api_url = config.baseHelper() + "/verify-user";
  let queries = queryString.parse(props.location.search);
  let body = {};
  if (queries.auth_token) {
    body["token"] = queries.auth_token;
  }
  axios
    .post(api_url, body)
    .then(async (result) => {
      config.setLoggedInUserInfo(result.data.user_info);
      config.setLoggedInUserRoles(result.data.user_roles);
      localStorage.setItem("auth", queries.auth_token);
      if (queries.user_info) {
        localStorage.setItem("user_info", queries.user_info);
      }
      window.location.replace("/" + queries.okrpath);
    })
    .catch((err) => {
      NotificationManager.error("Something went wrong");
      console.log(err, "wrrrrrrr");
      props.history.push("/forbidden");
    });

  return <div></div>;
}

export default VerfyUser;
