import * as ruleType from '../types/ruleTypes';

    const initialState ={
        assessmentNames : undefined,
        additionalNames : undefined,
        threeSixtyQuestions : undefined,
        threeSixtySelectedQuestions : undefined,
        ruleDetails : undefined,
        displayName : undefined,
    }

    export function ruleReducer(state = initialState, action) {
        let userData
        switch (action.type) {
            case ruleType.FETCH_RULE_DETAILS:
                userData = action.payload
                state = {
                    ...state,
                    ruleDetails : userData
                }
                return state
            case ruleType.FETCH_ASSESSMENT_NAMES:
                userData = action.payload
                state = {
                    ...state,
                    assessmentNames : userData
                }
                return state
            case ruleType.FETCH_ADDITIONAL_NAMES:
                userData = action.payload
                state = {
                    ...state,
                    additionalNames : userData
                }
                return state
            case ruleType.FETCH_360_QUESTIONS:
                userData = action.payload
                state = {
                    ...state,
                    fetch360Questions : userData
                }
                return state
            case ruleType.FETCH_SELECTED_360_QUESTIONS:
                userData = action.payload
                state = {
                    ...state,
                    threeSixtySelectedQuestions : userData
                }
                return state
            case ruleType.FETCH_DISPLAY_NAME:
                userData = action.payload
                state = {
                    ...state,
                    displayName : userData
                }
                return state
            default:
                return state
        }
    }
