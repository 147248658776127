import axios from 'axios';
var token = localStorage.getItem("auth");

export function page_code() {
  return window.location.pathname.split("/")[1];
}

export let pages = {
  employee_plan: 125,
  performance_plan: 126,
};
const LANGUAGE_ID = getUserLanguage()

let config = {
  base_url: "",
};

let parent_dom_info = {};

let loggedin_user_info = {};
export function baseHelper() {
  return config["base_url"];
}

export function getId() {
  return loggedin_user_info["company_id"];
}

let userRoles = {};

export function checkToken() {
  return true;
  // try {
  //   let tokenTime = parseInt(localStorage.getItem("time"));
  //   let currentTime = new Date().getTime();
  //   if (currentTime < tokenTime) {
  //     return true;
  //   } else {
  //     localStorage.clear();
  //     window.location.assign("/login");
  //     return false;
  //   }
  // } catch (err) {
  //   localStorage.clear();
  //   window.location.assign("/login");
  //   return false;
  // }
}

export function setConfig() {
  if (window.location.origin.includes("localhost")) {
    config["base_url"] = "http://localhost:8081/api/v1";
  } else {
    config["base_url"] = window.location.origin + "/api/v1";
  }
  console.log(config);
}

export function getParentDOMInfo() {
  let a = localStorage.getItem("parent_frame_info");
  if (a != "" && a != null) {
    a = JSON.parse(atob(a));
  }
  return a;
}

export let globalTranslationKeys = [];

async function updateHarvest(PAGE_ID, language) {
  try {
    await axios.post(baseHelper() + `/insert-source-text/` + LANGUAGE_ID, { original_text: language, page_code: PAGE_ID }, {
      headers: {
        Authorization: `${token}`,
      },
    })
  } catch (err) {
    console.log(err);
  }
}

export function translateWord(language, translationKeys, PAGE_ID) {
  if (translationKeys && translationKeys.hasOwnProperty(language)) {
    return translationKeys[language]
  } else {

    updateHarvest(PAGE_ID, language)

  }
  return language
}


export function setLoggedInUserInfo(data) {
  loggedin_user_info["user_id"] = data["ui"];
  loggedin_user_info["company_id"] = data["unit"];
  loggedin_user_info["user_email"] = data["det"];
  loggedin_user_info["role"] = data["role"];
  loggedin_user_info["language_id"] = LANGUAGE_ID

}

export function setLoggedInUserRoles(data) {
  userRoles = data;
}

export function getUserRoles() {
  return Object.assign({}, userRoles);
}

export function getUserSessionRole() {
  let a = localStorage.getItem("user_info");
  if (a) {
    a = JSON.parse(atob(a));
    return a.session_role;
  } else {
    return "";
  }
}

export function getUserLanguage() {
  let a = localStorage.getItem("user_info");
  if (a) {
    a = JSON.parse(atob(a));
    return (a.default_language) ? a.default_language : 1;
  } else {
    return 1;
  }
}

export function hideParentLoader() {
  let a = getParentDOMInfo();
  window.parent.postMessage({
    action: "hide_loader",
    called_from: window.location.href
  }, a.parent_url);
}

export default {
  baseHelper,
  checkToken,
  setConfig,
  pages,
  userRoles,
  getUserRoles,
};